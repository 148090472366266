import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 31.876 46.65 L 31.876 32.1 L 27.076 32.1 L 27.076 30.45 L 48.676 30.45 L 48.676 32.1 L 45.751 32.1 L 45.751 53.25 L 44.626 53.25 A 4.323 4.323 0 0 0 41.475 51.412 A 5.958 5.958 0 0 0 40.801 51.375 Q 39.903 51.375 35.956 52.364 A 183.626 183.626 0 0 0 34.538 52.725 A 52.156 52.156 0 0 1 30.991 53.521 Q 29.311 53.833 27.832 53.969 A 23.815 23.815 0 0 1 25.651 54.075 A 36.329 36.329 0 0 1 18.059 53.33 Q 13.324 52.318 9.737 49.93 A 20.527 20.527 0 0 1 6.713 47.475 Q 0.001 40.875 0.001 27.638 A 40.152 40.152 0 0 1 0.756 19.601 Q 2.302 12.038 7.013 7.2 A 23.316 23.316 0 0 1 19.922 0.508 A 33.407 33.407 0 0 1 25.876 0 Q 30.451 0 35.063 1.163 A 192.263 192.263 0 0 0 36.657 1.558 Q 39.427 2.231 40.237 2.314 A 1.918 1.918 0 0 0 40.426 2.325 A 2.666 2.666 0 0 0 40.838 2.296 Q 41.056 2.261 41.23 2.188 A 1.132 1.132 0 0 0 41.551 1.988 A 2.283 2.283 0 0 0 41.786 1.737 Q 42.018 1.454 42.275 1.006 A 9.679 9.679 0 0 0 42.376 0.825 L 43.201 0.825 L 43.801 17.1 L 42.451 17.1 A 76.665 76.665 0 0 0 39.805 12.273 Q 38.476 10.055 37.142 8.241 A 35.188 35.188 0 0 0 35.063 5.663 Q 31.351 1.5 26.776 1.5 Q 16.559 1.5 15.439 19.28 A 74.071 74.071 0 0 0 15.301 23.925 L 15.301 29.85 A 72.738 72.738 0 0 0 15.523 35.742 Q 16.092 42.714 18.113 46.838 A 13.346 13.346 0 0 0 19.888 49.611 A 7.91 7.91 0 0 0 26.176 52.575 A 15.645 15.645 0 0 0 27.656 52.511 Q 30.072 52.28 30.863 51.225 A 3.946 3.946 0 0 0 31.414 50.153 Q 31.782 49.1 31.857 47.504 A 18.18 18.18 0 0 0 31.876 46.65 Z"
          fill="currentColor"
          transform="translate(15,15)"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
